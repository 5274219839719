import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import moment from 'moment';
import { Dropdown, Menu, Space, Table, Tag, Select, Empty, DatePicker, Spin, Tooltip as AntTooltip, Drawer, Segmented, Input, Tabs, Button, Comment, Form, Avatar, List } from 'antd';
import { DownOutlined, DollarTwoTone, RiseOutlined, FallOutlined, MoreOutlined, CloseCircleOutlined, AppstoreOutlined, BarsOutlined, EditOutlined, FolderViewOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Sector, Cell } from 'recharts';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const COLORS = ['#39b351','#0088FE', '#FFBB28', '#FF8042'];

const Dashboard = () => {

  var today = new Date();
  var local_month= JSON.parse(localStorage.getItem('month'));
  if(local_month){
   var mm=local_month
  }else{
      var mm = String(today.getMonth() + 1).padStart(2, '0')
  }

  var yyyy = today.getFullYear()
  yyyy = yyyy.toString()

  var local_type = JSON.parse(localStorage.getItem('type'))
  var local_city = JSON.parse(localStorage.getItem('cityId'))
  var local_selectedVenueId = JSON.parse(localStorage.getItem('selectedVenueId'))
  if(!local_selectedVenueId){
    local_selectedVenueId = 'All'
  }
  if(!local_type){
    local_type = 'venue'
  }
  if(!local_city){
    local_city = ''
  }

  const [loading, setLoading] = useState(true)
  const [venues, setVenues] = useState([])
  const [cities, setCities] = useState([])
  const [type, setType] = useState(local_type)
  const [cityId, setCityId] = useState(local_city)
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [venuelist, setVenuelist] = useState([])
  const [month, setMonth] = useState(mm)
  const [year, setYear] = useState(yyyy)
  const [revenueSum, setRevenueSum] = useState('')
  const [collectionSum, setCollectionSum] = useState('')
  const [activeSum, setActiveSum] = useState('')
  const [graphData, setGraphData] = useState({})
  const [pieGraphData, setpieGraphData] = useState([])
  const [leadCount, setLeadCount] = useState('')
  const [splitUp, setSplitUp] = useState([])
  const [pieChartData, setPieChartData] = useState([])
  
  useEffect(() => {
     localStorage.setItem('month', JSON.stringify(month));
     localStorage.setItem('year', JSON.stringify(year));
     localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))
     localStorage.setItem('cityId',JSON.stringify(cityId))
     localStorage.setItem('type',JSON.stringify(type))
  }, [month,year,selectedVenueId,cityId,type]);

  useEffect(() => {
     getVenues()
  }, []); 

  useEffect(() => { 
    setLoading(true)
    getDashboardStats()
    getDashboardGraph()
  }, [month,year,selectedVenueId,cityId]);

  useEffect(() => {
    setLoading(true)
    getTypeUpdate(type)
    getVenuesInCity()
  }, [cities,cityId]);

  useEffect(()=>{
    setLoading(true)
    getHotLeadsGraph()
  }, [selectedVenueId,cityId]); 

  // useEffect(()=>{
  //   setLoading(true)
  //   getActiveCustomerSplit()
  // }, [selectedVenueId]);

  const getVenues = async () => {
    await getData('/business/get_venues_for_buddies/')
    .then(response =>{
      setVenues(response.result)
      setCities(response.cities)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const onChangeType = (value) => {
    setType(value)
    getTypeUpdate(value)
  }

  const getTypeUpdate=(value)=>{    
    if(value == 'city'){
      if(cities[0] && !cityId){
        setCityId(cities[0].id)
      }
    }
    if(value == 'venue'){
      setVenuelist(venues)
      setCityId('')
    }
    if(cities.length > 1){
      setSelectedVenueId('All')
    }
    setLoading(false)
  }

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };

  const getVenuesInCity =() =>{
    if (type == 'city' ){
      setVenuelist( venues.filter((item)=>{
        return item.city === cityId
       }))    
    }
  }

  const getDashboardStats = () => {
    getData(`/business/get_business_dashboard/?month=${month}&year=${year}&venue_id=${selectedVenueId}&city_id=${cityId}`)
    .then(response =>{
      setRevenueSum(response.revenue_sum)
      setCollectionSum(response.collection_sum)
      setActiveSum(response.active_customers)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const getDashboardGraph = () => {
    getData(`/business/get_dashboard_graph/?month=${month}&year=${year}&venue_id=${selectedVenueId}&city_id=${cityId}`)
    .then(response =>{
      setGraphData(response.result)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const getHotLeadsGraph = () => {
    getData(`/business/get_hot_leads_graph/?venue_id=${selectedVenueId}&city_id=${cityId}`)
    .then(response =>{
      setpieGraphData(response.result)
      setLeadCount(response.total)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const getActiveCustomerSplit = () => {
    var venues = selectedVenueId
    if(selectedVenueId == 'All'){
      venues = 0
    }
    var d = year + '-' + month
    getData(`/eagleeye/getTableDataJSON/?report_id=75382&venue_id=${venues}&date=${d}`)
    .then(response =>{
      setSplitUp(response.data[0])
      getDataFormat(response.data[0])
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }
  
  const getRandomColor = (key) => {
    let hash = 0;
    for (let i = 0; i < key.length; i++) {
        hash = key.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = '#' + ((hash >> 24) & 0xFF).toString(16).padStart(2, '0') +
                        ((hash >> 16) & 0xFF).toString(16).padStart(2, '0') +
                        ((hash >> 8) & 0xFF).toString(16).padStart(2, '0');
    return color;
  }; 

  const getDataFormat = (data) => {
    if(data && data['Total'] > 0){
      const transformedData = Object.keys(data).filter(key => key !== "Total" && parseInt(data[key], 10) !== 0).map(key => ({
        name: key,
        value: parseInt(data[key], 10)
      }));
      setPieChartData(transformedData)
    }
    setLoading(false)
  };  



  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.TopDiv>
          
          <Styled.SelectorContainer>
            {cities.length > 1 &&
              <>            
                <Styled.Selector>
                  <Select style={{width: 100}}
                    onChange={onChangeType}
                    value = {type}
                    bordered={false}
                  >
                    <Option value="city" > City </Option>
                    <Option value="venue"> Venue </Option>
                  </Select>
                </Styled.Selector>

              { type == 'city' &&          
                <Styled.Selector>
                  <Select
                    style={{width: 170}}
                    showSearch = {true}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                    onChange={setCityId}
                    value = {cityId}
                    bordered={false}
                  >
                    {
                      cities.map(item=>(
                        <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                      ))
                    }
                  </Select>
                </Styled.Selector>
              }
              </>
            }
            <Styled.Selector>
              <Select
                style={{width: 400}}
                showSearch = {true}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={setSelectedVenueId}
                value = {selectedVenueId}
                bordered={false}
              >
                <Option value="All" name="All"> All </Option>
                {venuelist.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                  ))
                }
              </Select>
            </Styled.Selector>
          </Styled.SelectorContainer>

          <div style={{display: 'flex'}}>
            <Styled.Selector>
              <Select
                  style={{width: 150}}
                  onChange={setMonth}
                  value = {month}
                  bordered={false}
                >
                  <Option value="12"> December </Option>
                  <Option value="11"> November </Option>
                  <Option value="10"> October </Option>
                  <Option value="09"> September </Option>
                  <Option value="08"> August </Option>
                  <Option value="07"> July </Option>
                  <Option value="06"> June </Option>
                  <Option value="05"> May </Option>
                  <Option value="04"> April </Option>
                  <Option value="03"> March </Option>
                  <Option value="02"> February </Option>
                  <Option value="01"> January </Option>
                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <DatePicker defaultValue={moment(year, 'YYYY')}  onChange = {onChangeYear}  bordered={false}  picker="year" />
            </Styled.Selector>
          </div>
        </Styled.TopDiv>

        <Styled.StatsDiv>

          <Styled.StatsBox>
            <Styled.IconLabelDiv>
              <DollarTwoTone twoToneColor="#c48e1a" style={{fontSize: 'large'}} />
              <Styled.StatsLabel> Revenue </Styled.StatsLabel>
            </Styled.IconLabelDiv>

            <Styled.StatsAmountDiv>
              <Styled.AmountLabel>
                {
                  revenueSum ?
                    revenueSum.toLocaleString('en-IN')
                  :
                    <span style={{color: 'grey', fontSize: 'small'}}> data not available </span>
                }
              </Styled.AmountLabel>
            </Styled.StatsAmountDiv>
          </Styled.StatsBox>

          <Styled.StatsBox>
            <Styled.IconLabelDiv>
              <DollarTwoTone twoToneColor="#1ab9c4" style={{fontSize: 'large'}} />
              <Styled.StatsLabel> Collection </Styled.StatsLabel>
            </Styled.IconLabelDiv>

            <Styled.StatsAmountDiv>
              <Styled.AmountLabel> 
                {
                  collectionSum ?
                    collectionSum.toLocaleString('en-IN')
                  :
                    <span style={{color: 'grey', fontSize: 'small'}}> data not available </span>
                }  
              </Styled.AmountLabel>             
            </Styled.StatsAmountDiv>
          </Styled.StatsBox>

          <Styled.StatsBox>
            <Styled.IconLabelDiv>
              <DollarTwoTone twoToneColor="#831ac4" style={{fontSize: 'large'}} />
              <Styled.StatsLabel> Active Customers </Styled.StatsLabel>
            </Styled.IconLabelDiv>

            <Styled.StatsAmountDiv>
              <Styled.AmountLabel> 
                {
                  activeSum ?
                    activeSum
                  :
                    <span style={{color: 'grey', fontSize: 'small'}}> data not available </span> 
                }  
              </Styled.AmountLabel>             
            </Styled.StatsAmountDiv>
          </Styled.StatsBox>

        </Styled.StatsDiv>

        <Styled.GraphDiv>
          
          <Styled.GraphDivLeft>
            <Styled.GraphDivLabelDiv>
              <Styled.LabelHeading> Active Customers - Analysis </Styled.LabelHeading>

              <Styled.GraphMonthSelector>
                Last 6 months
              </Styled.GraphMonthSelector>
            
            </Styled.GraphDivLabelDiv>

            <ResponsiveContainer width="100%" height="80%">
              <LineChart
                width={500}
                height={300}
                data={graphData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
              {/*  <Line type="monotone" dataKey="revenue" stroke="#c48e1a" />
                <Line type="monotone" dataKey="collection" stroke="#1a47c4" />*/}
                <Line type="monotone" dataKey="active customers" stroke="rgb(30 124 10)" />
              </LineChart>
            </ResponsiveContainer>
          </Styled.GraphDivLeft>
          <span></span>

          <Styled.GraphDivRight style={{alignItem: 'center'}}>

            <Styled.GraphDivLabelDiv>
              <Styled.LabelHeading> Hot Leads - Today </Styled.LabelHeading>
            </Styled.GraphDivLabelDiv>

            {
              leadCount > 0 ?              
                <Styled.PieDiv>
                              
                  <div style={{position: 'relative'}}>
                    <PieChart width={200} height={200}>
                      <Pie
                        data={pieGraphData}
                        innerRadius={60}
                        // outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {pieGraphData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                    <Styled.TotalAmount> {leadCount} </Styled.TotalAmount>
                  </div>                              
              
                  <Styled.PieDivInfo>
                    {
                      pieGraphData.map((item,index)=>(
                        <Styled.InfoRow>
                          <Styled.InfoColor style={{backgroundColor: index == 0 ? '#39b351' : index == 1 ? '#0088FE' : index == 2 ? '#FFBB28' : '#FF8042'}} />
                          <Styled.InfoLabel> {item.name} - {item.value}</Styled.InfoLabel>
                        </Styled.InfoRow>
                      ))
                    }  
                  </Styled.PieDivInfo>
              
                </Styled.PieDiv>
              :
               <Empty style={{marginTop: '10%'}} description = {<span style={{color: 'grey', fontSize: 'small'}}> data not available </span>} />
            }
            </Styled.GraphDivRight>

        </Styled.GraphDiv>
        
{/*        { splitUp && splitUp["Total"] > 0 &&
          <Styled.ChartDiv>
            <div style={{position: 'relative'}}>
              <PieChart width={200} height={200}>
                <Pie
                  data={pieChartData}
                  innerRadius={60}
                  // outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {Object.entries(splitUp).map(([key, value]) => (
                    key != 'Total' && value != 0 &&
                    <Cell key={`cell-${value}`} fill={getRandomColor(key)} />
                  ))}
                </Pie>
              </PieChart>
            </div>  
            <Styled.LabelContainer>
              {
                Object.entries(splitUp).map(([key, value])=>(
                  key != 'Total' && value != 0 &&
                  <Styled.InfoRow>
                    <Styled.InfoColor style={{backgroundColor: getRandomColor(key)}} />
                    <Styled.InfoLabel> {key} - {value}</Styled.InfoLabel>
                  </Styled.InfoRow>
                ))
              }  
            </Styled.LabelContainer>                    
          </Styled.ChartDiv>
        }*/}
      </Styled.MainContainer>
    </Spin>
  );
}

export default Dashboard;