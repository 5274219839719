import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: whitesmoke;
`;

export const NavBarContainer = styled.div`
  width: 50vw;
  background-color: white;
  border-radius: 2%;
  border: 1px solid whitesmoke;
  padding: 0px !important;
`;

export const Container = styled.div`
  margintop:10vh;
  marginbottom:10vh;
  align-items:center;
`;

export const Header = styled.div`
 height: 10vh;
 width:50vw;
 display:flex;
 justify-content:center;
 background-color:#02475c;
 margin-top:1%;
 padding: 10px;
 border-radius: 10px;
 `;

 export const Subheader = styled.div`
 height: 30vh;
 width:50vw;
 display:flex;
 align-items:center;
 justify-content:center;
 background-color:white;
 margintop:30vw;
 `;

 export const OTPhelper = styled.span`
  font-size: smaller;
  color: red;
  margin-left: 40%;
 `;

 

