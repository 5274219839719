import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,Link} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const Batches = () => {

  var local_type = JSON.parse(localStorage.getItem('type'))
  var local_city = JSON.parse(localStorage.getItem('cityId'))
  var local_selectedVenueId = JSON.parse(localStorage.getItem('selectedVenueId'))
  if(!local_selectedVenueId){
    local_selectedVenueId = 'All'
  }
  if(!local_type){
    local_type = 'venue'
  }

  const [loading, setLoading] = useState(true)
  const [venues, setVenues] = useState([])
  const [cities, setCities] = useState([])
  const [type, setType] = useState(local_type)
  const [cityId, setCityId] = useState(local_city)
  const [venuelist, setVenuelist] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [batchesData, setBatchesData] = useState([])

  useEffect(() => {
    localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))
    localStorage.setItem('cityId',JSON.stringify(cityId))
    localStorage.setItem('type',JSON.stringify(type))
  }, [selectedVenueId,cityId,type]);

  useEffect(() => {
     getVenues()
  }, []); 

  useEffect(() => {
    setLoading(true)
    getBatchDetails()
  }, [selectedVenueId,cityId]); 

  useEffect(() => {
    setLoading(true)
    getTypeUpdate(type)
    getVenuesInCity()
  }, [cities,cityId]);

  const onChangeType = (value) => {
    setType(value)
    getTypeUpdate(value)
  }

  const getTypeUpdate=(value)=>{    
    if(value == 'city'){
      if(cities[0] && !cityId){
        setCityId(cities[0].id)
      }
    }
    if(value == 'venue'){
      setVenuelist(venues)
      setCityId('')
    }
    if(cities.length > 1){
      setSelectedVenueId('All')
    }
  }

  const getVenuesInCity =() =>{
    if (type == 'city' ){
      setVenuelist( venues.filter((item)=>{
        return item.city === cityId
       }))    
    }
  }

  const getVenues = async () => {
    await getData('/business/get_venues_for_buddies/')
    .then(response =>{
      setVenues(response.result)
      setCities(response.cities)
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  const getBatchDetails = () => {
    getData(`/business/get_batches_summary/?venue_id=${selectedVenueId}&city_id=${cityId}`)
    .then(response =>{
      setBatchesData(response.result)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const columns = [
    {
      title: 'Venue',
      render: (_, record) => {
      const { venue, venue_id} = record;
      return (
          <Link to={`/batchDetails/${venue_id}`}>
            {
              venue
            }
          </Link>
          )
      },
    },

    {
      title: 'No Of Batches',
      dataIndex: 'no_of_batches',
      sorter: (a, b) => a.no_of_batches - b.no_of_batches
    },

    {
      title: 'Capacity',
      dataIndex: 'capacity',
      sorter: (a, b) => a.capacity - b.capacity
    },

    {
      title: 'Active',
      dataIndex: 'active_customers',
      sorter: (a, b) => a.active_customers - b.active_customers
    },

    {
      title: 'Utilization',
      dataIndex: 'utilization',
      render: (value) => {
        return(
          <span style={{padding:'5px',borderRadius:'5px',fontWeight:600,backgroundColor: value >= 80 ? '#b3f6b3' : (value < 80 && value > 40) ? '#f7b08d' : '#f79292'}} >
            {value + '%'}                
          </span>
          )
      },
      sorter: (a, b) => a.utilization - b.utilization
    }
  ]

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.TopDiv>    
          <Styled.SelectorContainer>

            {cities.length > 1 &&
              <>            
                <Styled.Selector>
                  <Select style={{width: 100}}
                    onChange={onChangeType}
                    value = {type}
                    bordered={false}
                  >
                    <Option value="city" > City </Option>
                    <Option value="venue"> Venue </Option>
                  </Select>
                </Styled.Selector>

              { type == 'city' &&          
                <Styled.Selector>
                  <Select
                    style={{width: 170}}
                    showSearch = {true}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                    onChange={setCityId}
                    value = {cityId}
                    bordered={false}
                  >
                    {
                      cities.map(item=>(
                        <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                      ))
                    }
                  </Select>
                </Styled.Selector>
              }
              </>
            }

            <Styled.Selector> 
              <Select
                style={{width: 400}}
                showSearch = {true}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={setSelectedVenueId}
                value = {selectedVenueId}
                bordered={false}
              >
                <Option value="All" name="All"> All </Option>
                {venuelist.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                  ))
                }
              </Select>
            </Styled.Selector>
          </Styled.SelectorContainer>
        </Styled.TopDiv>

        <Styled.Header>          
          <span> Batch Overview</span>
        </Styled.Header>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={batchesData}
          pagination={{ pageSize: 20, showSizeChanger: false}}
         />

      </Styled.MainContainer>
    </Spin>
  );
}

export default Batches;