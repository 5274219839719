import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
`;

export const TopDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SelectorContainer = styled.div`
	display: flex;
`;

export const Selector = styled.div`
	background-color: #f0fffe;
	padding: 7px;
	border-radius: 5px;
	margin-right: 20px;
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content : center;
  font-size: large;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Icon = styled.a`
  cursor: pointer;
  color: grey;
  font-size: large;

  &:hover {
    color: #02475c;
    font-size: xx-large;
    font-weight: 900; 
    transition: transform 0.3s ease;
  }
`;

