import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,Link} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const CollectionList = () => {
  var today = new Date();
  var local_month = JSON.parse(localStorage.getItem('month'));
  if(local_month){
   var mm = local_month
  }else{
      var mm = String(today.getMonth() + 1).padStart(2, '0')
  }

  var yyyy = today.getFullYear()
  yyyy = yyyy.toString()

  var local_type = JSON.parse(localStorage.getItem('type'))
  var local_city = JSON.parse(localStorage.getItem('cityId'))
  var local_selectedVenueId = JSON.parse(localStorage.getItem('selectedVenueId'))
  if(!local_selectedVenueId){
    local_selectedVenueId = 'All'
  }
  if(!local_type){
    local_type = 'venue'
  }

  const [loading, setLoading] = useState(true)
  const [venues, setVenues] = useState([])
  const [cities, setCities] = useState([])
  const [type, setType] = useState(local_type)
  const [cityId, setCityId] = useState(local_city)
  const [venuelist, setVenuelist] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [collectionData, setCollectionData] = useState([])
  const [month, setMonth] = useState(mm)
  const [year, setYear] = useState(yyyy)

  useEffect(() => {
    localStorage.setItem('month', JSON.stringify(month));
    localStorage.setItem('year', JSON.stringify(year));
    localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))
    localStorage.setItem('cityId',JSON.stringify(cityId))
    localStorage.setItem('type',JSON.stringify(type))
  }, [month,year,selectedVenueId,cityId,type]);

  useEffect(() => {
     getVenues()
  }, []); 

  useEffect(() => {
    setLoading(true)
    getCollection()
  }, [month,year,selectedVenueId,cityId]); 

  useEffect(() => {
    setLoading(true)
    getTypeUpdate(type)
    getVenuesInCity()
  }, [cities,cityId]);

  const onChangeType = (value) => {
    setType(value)
    getTypeUpdate(value)
  }

  const getTypeUpdate=(value)=>{    
    if(value == 'city'){
      if(cities[0] && !cityId){
        setCityId(cities[0].id)
      }
    }
    if(value == 'venue'){
      setVenuelist(venues)
      setCityId('')
    }
    if(cities.length > 1){
      setSelectedVenueId('All')
    }
    setLoading(false)
  }

  const getVenuesInCity =() =>{
    if (type == 'city' ){
      setVenuelist( venues.filter((item)=>{
        return item.city === cityId
       }))    
    }
  }

  const getVenues = async () => {
    await getData('/business/get_venues_for_buddies/')
    .then(response =>{
      setVenues(response.result)
      setCities(response.cities)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };  

  const getCollection = () => {
    getData(`/business/get_collection_overview/?month=${month}&year=${year}&venue_id=${selectedVenueId}&city_id=${cityId}`)
    .then(response =>{
      setCollectionData(response.result)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }

  const columns = [
    {
      title: 'Venue',
      dataIndex: 'venue',
    },

    {
      title: 'Collection',
      dataIndex: 'collection',
      render: (value) => {
        return value.toLocaleString('en-IN')
      },
    },
  ]

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.TopDiv>    
          <Styled.SelectorContainer>

            {cities.length > 1 &&
              <>            
                <Styled.Selector>
                  <Select style={{width: 100}}
                    onChange={onChangeType}
                    value = {type}
                    bordered={false}
                  >
                    <Option value="city" > City </Option>
                    <Option value="venue"> Venue </Option>
                  </Select>
                </Styled.Selector>

              { type == 'city' &&          
                <Styled.Selector>
                  <Select
                    style={{width: 170}}
                    showSearch = {true}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                    onChange={setCityId}
                    value = {cityId}
                    bordered={false}
                  >
                    {
                      cities.map(item=>(
                        <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                      ))
                    }
                  </Select>
                </Styled.Selector>
              }
              </>
            }

            <Styled.Selector>
              <Select
                style={{width: 400}}
                showSearch = {true}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={setSelectedVenueId}
                value = {selectedVenueId}
                bordered={false}
              >
                <Option value="All" name="All"> All </Option>
                {venuelist.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                  ))
                }
              </Select>
            </Styled.Selector>
          </Styled.SelectorContainer>

          <div style={{display: 'flex'}}>
            <Styled.Selector>
              <Select
                  style={{width: 150}}
                  onChange={setMonth}
                  value = {month}
                  bordered={false}
                >
                  <Option value="12"> December </Option>
                  <Option value="11"> November </Option>
                  <Option value="10"> October </Option>
                  <Option value="09"> September </Option>
                  <Option value="08"> August </Option>
                  <Option value="07"> July </Option>
                  <Option value="06"> June </Option>
                  <Option value="05"> May </Option>
                  <Option value="04"> April </Option>
                  <Option value="03"> March </Option>
                  <Option value="02"> February </Option>
                  <Option value="01"> January </Option>
                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <DatePicker defaultValue={moment(year, 'YYYY')}  onChange = {onChangeYear}  bordered={false}  picker="year" />
            </Styled.Selector>
          </div>
        </Styled.TopDiv>

        <Styled.Header>          
          <span> Collection List</span>
        </Styled.Header>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={collectionData}
          pagination={{ pageSize: 50, showSizeChanger: false}}
         />

      </Styled.MainContainer>
    </Spin>
  );
}

export default CollectionList;